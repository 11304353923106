// Works cognito side not api
// const aws_exports = {
//   Auth: {
//     Cognito: {
//       region: "us-east-1", 
//       userPoolId: "us-east-1_qlrTcQhbQ", 
//       userPoolClientId: "t069ve613pe9aaonnuqau13r1", 
//       identityPoolId: "us-east-1:ff873897-f6e3-421a-8b72-05cbcadc90a2",
//     },
//   }, 
//   API: {
//     endpoints: [
//       {name: "code", endpoint: "https://de0eslvfga.execute-api.us-east-1.amazonaws.com/PROD/"},
//       {name: "codev2", endpoint: "https://hjizsqgesl.execute-api.us-east-1.amazonaws.com/PROD/"}
//     ]
//   }, 
//   Websocket: "wss://zm32lec7b8.execute-api.us-east-1.amazonaws.com/PROD"
// };
// export default aws_exports;



const aws_exports = {
  Auth: {
    Cognito: {
      region: "us-east-1", 
      userPoolId: "us-east-1_qlrTcQhbQ", 
      userPoolClientId: "t069ve613pe9aaonnuqau13r1", 
      identityPoolId: "us-east-1:ff873897-f6e3-421a-8b72-05cbcadc90a2",
    },
  }, 
  API: {
    REST: {
      "code": {
        endpoint: "https://de0eslvfga.execute-api.us-east-1.amazonaws.com/PROD/",
        region: "us-east-1"
      },
      "codev2": {
        endpoint: "https://hjizsqgesl.execute-api.us-east-1.amazonaws.com/PROD/",
        region: "us-east-1"
      }
    }
  }, 
  Websocket: "wss://zm32lec7b8.execute-api.us-east-1.amazonaws.com/PROD"
};
export default aws_exports;
