import CircularProgress from '@mui/material/CircularProgress';
import banner from "../images/banner2.png";

const Loading = () => {
  return (
    <div>
      <section className="home-dark" style={{paddingTop: "0", paddingBottom: "0"}}>
        <img src={banner} width="100%" alt="Welcome logo" />
      </section>

      <section className="home-light center-align">
        <CircularProgress color="secondary" size="1.5rem"/> 
      </section>
    </div>
  )
}

export default Loading;